/*! `Custom` Bootstrap 4 theme */

$enable-grid-classes: false;
$primary: #f05324;
$secondary: #0c2d55;
$success: #28a745;
$danger: #dc3545;
$info: #17a2b8;
$warning: #ffc107;
$light: #f8f9fa;
$dark: #343a40;
@import "bootstrap";

// Add SASS theme customizations here..
html {
  font-size: 0.8rem;
}
@include media-breakpoint-up(sm) {
  html {
    font-size: 0.9rem;
  }
}
@include media-breakpoint-up(md) {
  html {
    font-size: 0.9rem;
  }
}
@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
}
